/* Portfolio.css */
@font-face {
    font-family: 'DrukTextWide'; /* Replace 'YourFontName' with a suitable name */
    src: local('DrukTextWide'), url('./fonts/DrukTextWide_Medium.ttf') format('truetype');}



.portfolio-container {
        font-family: 'DrukTextWide', sans-serif;
      
        /* Full height and width */
        height: 100vh; /* Full viewport height */
        width: 100%; /* Full width */
      
        /* Flexbox for centering */
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
      
        /* Background color */
        background-color: #307fda; /* Replace with your color */
        color: #004aa4
}
.logo-portfolio {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
  }
  