/* Homepage.css */
@font-face {
  font-family: 'DrukTextWide';
  src: local('DrukTextWide'), url('./fonts/DrukTextWide_Medium.ttf') format('truetype');
}

.homepage-section {
  position: relative;
  height: calc(170 * var(--vh));
  background-color: #307fda;
}

.fixed-bar {
  position: fixed;
  top: 0;            /* Attach the bar to the top of the viewport */
  left: 0;           /* Start from the leftmost point of the viewport */
  width: 100%;       /* Span the entire width */
  height: 40px;      /* Adjust according to your preference */
  background-color: #307fda;  /* Any color you like for the bar */
  z-index: 999;     /* Ensure it's above other content */
  display: flex;     /* To help in centering the logo */
  align-items: center;  /* Vertically center the logo */
  justify-content: center; /* Horizontally center the logo */
}


.fixed-bar .logo {
  width: 20%;  /* Or any size you prefer */
  height: auto;
  margin-top: 1%;  /* Reset any margins */
}

.button-left,
.button-right,
.button-middle {
  position: absolute;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  border: none;
  background: none;
  font-family: 'DrukTextWide', sans-serif;
  color: #004aa4;
}

.button-left:visited,
.button-right:visited,
.button-middle:visited {
  color: #004aa4;
}

.button-left:hover,
.button-right:hover,
.button-middle:hover {
  color: blue;
}

.button-left {
  top: 63px;
  left: 240px;
}

.button-middle {
  top: 63px;
  right: 450px;
}

.button-right {
  top: 63px;
  right: 150px;
}

.josephkonsum-image {
  position: fixed;
  bottom: 15px;
  left: 10px;
  width: 30px;
  height: auto;
}

.hidden {
  display: none !important;
}

.text-block-1 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  top: 8%; /* 100% - 75% */
  left: 5%; /* As you wanted it 75% toward the left */
  max-width: 45%; /* To ensure it doesn't take more than half the page width */
  font-size: 8px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  text-align: justify;
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}


.image_building-container {
  display: flex;
  justify-content: space-between; /* To distribute the images evenly */
  left: 62%;
  position: absolute; /* This will allow you to specify exactly where you want this container */
  bottom: 80%; /* Adjust this to position the images closer or further from the bottom */
  width: auto; /* Ensure the container takes the full width of the parent */
}

.image_building-container > img {
  /* You can set a width or height to control image size if desired */
  width: auto;
  max-width: 90%;  /* For example */
  height: auto;
}

.image-3-placeholder {
  position: fixed;
  bottom: 0%;
  left: 30%; /* Same left alignment as Text Block 1 */
  max-width: 40%; /* Similar width constraint to Text Block 1 for consistency */
  /* Assuming a 16:9 aspect ratio for the image, adjust as needed */
  height: auto; /* To maintain aspect ratio */
  /* Placeholder styling */
  display: block;  /* To make sure width and height are respected */
  content: 'Image 2 Placeholder';  /* Placeholder text */
  z-index: 1000;
}


.image_juxta-container {
  display: flex;
  justify-content: space-between; /* To distribute the images evenly */
  left: 69%;
  position: absolute; /* This will allow you to specify exactly where you want this container */
  bottom: 67.5%; /* Adjust this to position the images closer or further from the bottom */
  width: auto; /* Ensure the container takes the full width of the parent */
}

.image_juxta-container > img {
  /* You can set a width or height to control image size if desired */
  width: auto;
  max-width: 80%;  /* For example */
  height: auto;
}




.text-block-2 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  font-weight: bold;
  top: 62%; /* 100% - 75% */
  left: 5%; /* As you wanted it 75% toward the left */
  max-width: 90%; /* To ensure it doesn't take more than half the page width */
  font-size: 14px; /* Adjust as necessary */
  line-height: 1.3; /* To give a bit of spacing between lines */
  text-align: justify;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text-block-3 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  font-weight: bold;
  top: 58%; /* 100% - 75% */
  right: 16%; /* As you wanted it 75% toward the left */
  max-width: 70%; /* To ensure it doesn't take more than half the page width */
  font-size: 15px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  text-align: justify;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text-block-4 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  font-weight: bold;
  top: 32.8%; /* 100% - 75% */
  left: 75%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 6px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text-block-5 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  top: 33.8%; /* 100% - 75% */
  font-weight:5px;
  left: 77%; /* As you wanted it 75% toward the left */
  max-width: 50%; /* To ensure it doesn't take more than half the page width */
  font-size: 4px; /* Adjust as necessary */
  line-height: 0.8; /* To give a bit of spacing between lines */
  text-align: justify;
  color: #004aa4;
  text-align: right;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text-block-6 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  top: 8%; /* 100% - 75% */
  right: 5%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 16px; /* Adjust as necessary */
  line-height: 0.8; /* To give a bit of spacing between lines */
  font-weight: lighter;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}


.image_shopper-container {
  display: flex;
  justify-content: space-between; /* To distribute the images evenly */
  left: 32%;
  position: absolute; /* This will allow you to specify exactly where you want this container */
  bottom: 50%; /* Adjust this to position the images closer or further from the bottom */
  width: auto; /* Ensure the container takes the full width of the parent */
  height: 10%;
  align-items: center;
}

.image_shopper-container > img {
  /* You can set a width or height to control image size if desired */
  width: auto;
  max-width: 46%;  /* For example */
  height: auto;
}




.text-block-7 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  font-weight: bold;
  top: 51.5%; /* 100% - 75% */
  right: 38%; /* As you wanted it 75% toward the left */
  max-width: 70%; /* To ensure it doesn't take more than half the page width */
  font-size: 6px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  text-align: justify;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text-block-8 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  font-weight: lighter;
  top: 52.4%; /* 100% - 75% */
  right: 38%; /* As you wanted it 75% toward the left */
  max-width: 70%; /* To ensure it doesn't take more than half the page width */
  font-size: 4px; /* Adjust as necessary */
  line-height: 0.8; /* To give a bit of spacing between lines */
  text-align: right;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}







@media (min-width: 1024px) {

  .homepage-section {
    position: relative;
    height: 100vh;
    background-color: #307fda;
  }

  .fixed-bar {
    position: fixed;
    top: 0;            /* Attach the bar to the top of the viewport */
    left: 0;           /* Start from the leftmost point of the viewport */
    width: 100%;       /* Span the entire width */
    height: 90px;      /* Adjust according to your preference */
    background-color: #307fda;  /* Any color you like for the bar */
    z-index: 999;     /* Ensure it's above other content */
    display: flex;     /* To help in centering the logo */
    align-items: center;  /* Vertically center the logo */
    justify-content: center; /* Horizontally center the logo */
  }


  .fixed-bar .logo {
    width: 20%;  /* Or any size you prefer */
    height: auto;
    margin-top: 1%;  /* Reset any margins */
  }

  .josephkonsum-image {
    position: fixed;
    bottom: 15px;
    left: 25px;
    width: 100px;
    height: auto;
    z-index:1001;
  }

  .text-block-1 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 15%; /* 100% - 75% */
    left: 5%; /* As you wanted it 75% toward the left */
    max-width: 45%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }


  .image_building-container {
    display: flex;
    justify-content: space-between; /* To distribute the images evenly */
    left: 72%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 48%; /* Adjust this to position the images closer or further from the bottom */
    width: auto; /* Ensure the container takes the full width of the parent */
  }
  
  .image_building-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 80%;  /* For example */
    height: auto;
  }

  .image-3-placeholder {
    position: fixed;
    bottom: 0%;
    left: 40%; /* Same left alignment as Text Block 1 */
    max-width: 100%; /* Similar width constraint to Text Block 1 for consistency */
    /* Assuming a 16:9 aspect ratio for the image, adjust as needed */
    height: auto; /* To maintain aspect ratio */
    /* Placeholder styling */
    display: block;  /* To make sure width and height are respected */
    content: 'Image 2 Placeholder';  /* Placeholder text */
    z-index: 1000;
  }


  .image_juxta-container {
    display: flex;
    justify-content: space-between; /* To distribute the images evenly */
    left: 17%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 11%; /* Adjust this to position the images closer or further from the bottom */
    width: auto; /* Ensure the container takes the full width of the parent */
  }
  
  .image_juxta-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 20%;  /* For example */
    height: auto;
  }

  .text-block-2 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    font-weight: bold;
    top: 65%; /* 100% - 75% */
    left: 35%; /* As you wanted it 75% toward the left */
    max-width: 60%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.4; /* To give a bit of spacing between lines */
    text-align: justify;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text-block-3 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    font-weight: bold;
    bottom: 40%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 50%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text-block-4 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    font-weight: bold;
    top: 90%; /* 100% - 75% */
    left: 23.2%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 10px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text-block-5 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 92%; /* 100% - 75% */
    font-weight:10px;
    left: 24%; /* As you wanted it 75% toward the left */
    max-width: 50%; /* To ensure it doesn't take more than half the page width */
    font-size: 7px; /* Adjust as necessary */
    line-height: 0.8; /* To give a bit of spacing between lines */
    text-align: justify;
    color: #004aa4;
    text-align: right;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text-block-6 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 15%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 16px; /* Adjust as necessary */
    line-height: 0.8; /* To give a bit of spacing between lines */
    font-weight: lighter;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .image_shopper-container {
    display: none;
    justify-content: space-between; /* To distribute the images evenly */
    left: 32%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 50%; /* Adjust this to position the images closer or further from the bottom */
    width: 50%; /* Ensure the container takes the full width of the parent */
  }
  
  .image_shopper-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 45%;  /* For example */
    height: auto;
  }

  .text-block-7 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    font-weight: bold;
    top: 51.5%; /* 100% - 75% */
    right: 38%; /* As you wanted it 75% toward the left */
    max-width: 70%; /* To ensure it doesn't take more than half the page width */
    font-size: 6px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  
  .text-block-8 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    font-weight: lighter;
    top: 52.4%; /* 100% - 75% */
    right: 38%; /* As you wanted it 75% toward the left */
    max-width: 70%; /* To ensure it doesn't take more than half the page width */
    font-size: 4px; /* Adjust as necessary */
    line-height: 0.8; /* To give a bit of spacing between lines */
    text-align: right;
    color: #004aa4;
    display: none;

    /* Add other styles as needed, like font-family, color, etc. */
  }
  
  }