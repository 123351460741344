.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #307fda; /* Replace "your-color" with the color of your choice */
  }
  
  .landing-image {
    max-width: 70%;
    max-height: 70%;
    margin-left: 15%;
  }
  
  button {
    margin-top: 20px;
    cursor: pointer;
    background-color: black; /* Replace with the color of your choice */
    color: #004aa4;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    display: none;
  }
  
  .landing-image-button {
    cursor: pointer;
  }
  
  .landing-image-button:hover .landing-image {
    opacity: 0.8; /* Adjust to achieve the desired hover effect */
  }


@media (min-width: 1024px) {
  .landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #307fda; /* Replace "your-color" with the color of your choice */
  }
  
  .landing-image {
    max-width: 70%;
    max-height: 70%;
    margin-left: 225px;
  }
  
  button {
    margin-top: 20px;
    cursor: pointer;
    background-color: black; /* Replace with the color of your choice */
    color: #004aa4;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    display:block;
  }
  
  .landing-image-button {
    cursor: pointer;
  }
  
  .landing-image-button:hover .landing-image {
    opacity: 0.8; /* Adjust to achieve the desired hover effect */
  }

}