@font-face {
    font-family: 'DrukTextWide';
    src: local('DrukTextWide'), url('./fonts/DrukTextWide_Medium.ttf') format('truetype');
}
  
.homepage_cont-section {
    background-color: #307fda;
    position: relative;
    height: calc(260 * var(--vh));
}
  

.text_cont-block-1 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 6%; /* 100% - 75% */
    left: 23%; /* As you wanted it 75% toward the left */
    max-width: 85%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text_cont-block-2 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 9%; /* 100% - 75% */
    left: 5%; /* As you wanted it 75% toward the left */
    max-width: 90%; /* To ensure it doesn't take more than half the page width */
    font-size: 14px; /* Adjust as necessary */
    line-height: 1.3; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text_cont-block-3 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 96%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 7px; /* Adjust as necessary */
    line-height: 1; /* To give a bit of spacing between lines */
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  .text_cont-block-4 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 95%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 4px; /* Adjust as necessary */
    line-height: 0.8; /* To give a bit of spacing between lines */
    text-align: right;
    font-weight: lighter;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text_cont-block-5 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 12.4%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 16px; /* Adjust as necessary */
    line-height: 0.8; /* To give a bit of spacing between lines */
    text-align: right;
    font-weight: lighter;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .josephkonsum_cont-image {
    position: absolute;
    bottom: 3%;
    left: 2%;
    width: 6%;
    height: auto;
  }


  .image_cont-container {
    display: flex;
    justify-content: space-between; /* To distribute the images evenly */
    left: 5%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 97%; /* Adjust this to position the images closer or further from the bottom */
    width: 90%; /* Ensure the container takes the full width of the parent */
}

.image_cont-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 9%;  /* For example */
    height: auto;
}
/* Add any other desired styles... */


.image_flights-container {
  display: flex;
  justify-content: space-between; /* To distribute the images evenly */
  left: 33%;
  position: absolute; /* This will allow you to specify exactly where you want this container */
  bottom: 64%; /* Adjust this to position the images closer or further from the bottom */
  width: auto; /* Ensure the container takes the full width of the parent */
}

.image_flights-container > img {
  /* You can set a width or height to control image size if desired */
  width: auto;
  max-width: 50%;  /* For example */
  height: auto;
}


.image_idole-container {
  display: flex;
  justify-content: space-between; /* To distribute the images evenly */
  left: 36%;
  position: absolute; /* This will allow you to specify exactly where you want this container */
  bottom: 42%; /* Adjust this to position the images closer or further from the bottom */
  width: auto; /* Ensure the container takes the full width of the parent */
}

.image_idole-container > img {
  /* You can set a width or height to control image size if desired */
  width: auto;
  max-width: 47%;  /* For example */
  height: auto;
}

.image_cont_2-container {
  display: flex;
  justify-content: space-between; /* To distribute the images evenly */
  left: 5%;
  position: absolute; /* This will allow you to specify exactly where you want this container */
  top: 75%; /* Adjust this to position the images closer or further from the bottom */
  width: 90%; /* Ensure the container takes the full width of the parent */
}

.image_cont_2-container > img {
  /* You can set a width or height to control image size if desired */
  width: auto;
  max-width: 18%;  /* For example */
  height: auto;
}

.text_cont-block-6 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  top: 40%; /* 100% - 75% */
  left: 5%; /* As you wanted it 75% toward the left */
  max-width: 85%; /* To ensure it doesn't take more than half the page width */
  font-size: 15px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  text-align: justify;
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text_cont-block-7 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  top: 61%; /* 100% - 75% */
  left: 5%; /* As you wanted it 75% toward the left */
  max-width: 85%; /* To ensure it doesn't take more than half the page width */
  font-size: 15px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  text-align: justify;
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text_cont-block-8 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  top: 86%; /* 100% - 75% */
  left: 5%; /* As you wanted it 75% toward the left */
  max-width: 85%; /* To ensure it doesn't take more than half the page width */
  font-size: 15px; /* Adjust as necessary */
  line-height: 1.2; /* To give a bit of spacing between lines */
  text-align: justify;
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text_cont-block-9 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  bottom: 63.2%; /* 100% - 75% */
  right: 35%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 6px; /* Adjust as necessary */
  line-height: 1; /* To give a bit of spacing between lines */
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}
.text_cont-block-10 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  bottom: 62.4%; /* 100% - 75% */
  right: 35%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 4px; /* Adjust as necessary */
  line-height: 1.3; /* To give a bit of spacing between lines */
  text-align: right;
  font-weight: lighter;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text_cont-block-11 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  bottom: 41.2%; /* 100% - 75% */
  right: 35%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 6px; /* Adjust as necessary */
  line-height: 1; /* To give a bit of spacing between lines */
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}
.text_cont-block-12 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  bottom: 40.5%; /* 100% - 75% */
  right: 35%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 4px; /* Adjust as necessary */
  line-height: 1.3; /* To give a bit of spacing between lines */
  text-align: right;
  font-weight: lighter;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}

.text_cont-block-13 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  bottom: 18.8%; /* 100% - 75% */
  right: 5%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 6px; /* Adjust as necessary */
  line-height: 1; /* To give a bit of spacing between lines */
  font-weight: bold;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}
.text_cont-block-14 {
  position: absolute; /* Allow precise positioning */
  font-family: 'DrukTextWide', sans-serif;
  bottom: 18%; /* 100% - 75% */
  right: 5%; /* As you wanted it 75% toward the left */
  max-width: 100%; /* To ensure it doesn't take more than half the page width */
  font-size: 4px; /* Adjust as necessary */
  line-height: 1.3; /* To give a bit of spacing between lines */
  text-align: right;
  font-weight: lighter;
  color: #004aa4;
  /* Add other styles as needed, like font-family, color, etc. */
}





@media (min-width: 1024px) {

  .homepage_cont-section {
    background-color: #307fda;
    position: relative;
    height: 100vh;
  }


  .text_cont-block-1 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 15%; /* 100% - 75% */
    left: 44%; /* As you wanted it 75% toward the left */
    max-width: 45%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text_cont-block-2 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 22%; /* 100% - 75% */
    left: 5%; /* As you wanted it 75% toward the left */
    max-width: 90%; /* To ensure it doesn't take more than half the page width */
    font-size: 16px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text_cont-block-3 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 33%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 12px; /* Adjust as necessary */
    line-height: 1; /* To give a bit of spacing between lines */
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  .text_cont-block-4 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 30%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 7px; /* Adjust as necessary */
    line-height: 0.8; /* To give a bit of spacing between lines */
    text-align: right;
    font-weight: lighter;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }


  .josephkonsum_cont-image {
    position: absolute;
    bottom: 3%;
    left: 2%;
    width: 6%;
    height: auto;
  }


  .image_cont-container {
    display: flex;
    justify-content: space-between; /* To distribute the images evenly */
    left: -5%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 35%; /* Adjust this to position the images closer or further from the bottom */
    width: 90%; /* Ensure the container takes the full width of the parent */
    padding: 0 10%;
  }

  .image_cont-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 8%;  /* For example */
    height: auto;
  }
  /* Add any other desired styles... */



  

  .image_cont_2-container {
    display: none;
    justify-content: space-between; /* To distribute the images evenly */
    left: 5%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    top: 75%; /* Adjust this to position the images closer or further from the bottom */
    width: 90%; /* Ensure the container takes the full width of the parent */
  }
  
  .image_cont_2-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 18%;  /* For example */
    height: auto;
  }

  .text_cont-block-6 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 40%; /* 100% - 75% */
    left: 5%; /* As you wanted it 75% toward the left */
    max-width: 85%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  
  .text_cont-block-7 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 74%; /* 100% - 75% */
    left: 5%; /* As you wanted it 75% toward the left */
    max-width: 85%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  
  .text_cont-block-8 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    top: 86%; /* 100% - 75% */
    left: 10%; /* As you wanted it 75% toward the left */
    max-width: 85%; /* To ensure it doesn't take more than half the page width */
    font-size: 15px; /* Adjust as necessary */
    line-height: 1.2; /* To give a bit of spacing between lines */
    text-align: justify;
    font-weight: bold;
    color: #004aa4;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .text_cont-block-9 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 63.2%; /* 100% - 75% */
    right: 35%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 6px; /* Adjust as necessary */
    line-height: 1; /* To give a bit of spacing between lines */
    font-weight: bold;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  .text_cont-block-10 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 62.4%; /* 100% - 75% */
    right: 35%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 4px; /* Adjust as necessary */
    line-height: 1.3; /* To give a bit of spacing between lines */
    text-align: right;
    font-weight: lighter;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  
  .text_cont-block-11 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 41.2%; /* 100% - 75% */
    right: 35%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 6px; /* Adjust as necessary */
    line-height: 1; /* To give a bit of spacing between lines */
    font-weight: bold;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  .text_cont-block-12 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 40.5%; /* 100% - 75% */
    right: 35%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 4px; /* Adjust as necessary */
    line-height: 1.3; /* To give a bit of spacing between lines */
    text-align: right;
    font-weight: lighter;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  
  .text_cont-block-13 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 18.8%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 6px; /* Adjust as necessary */
    line-height: 1; /* To give a bit of spacing between lines */
    font-weight: bold;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }
  .text_cont-block-14 {
    position: absolute; /* Allow precise positioning */
    font-family: 'DrukTextWide', sans-serif;
    bottom: 18%; /* 100% - 75% */
    right: 5%; /* As you wanted it 75% toward the left */
    max-width: 100%; /* To ensure it doesn't take more than half the page width */
    font-size: 4px; /* Adjust as necessary */
    line-height: 1.3; /* To give a bit of spacing between lines */
    text-align: right;
    font-weight: lighter;
    color: #004aa4;
    display: none;
    /* Add other styles as needed, like font-family, color, etc. */
  }

  .image_flights-container {
    display: none;
    justify-content: space-between; /* To distribute the images evenly */
    left: 33%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 64%; /* Adjust this to position the images closer or further from the bottom */
    width: auto; /* Ensure the container takes the full width of the parent */
  }
  
  .image_flights-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 50%;  /* For example */
    height: auto;
  }
  
  
  .image_idole-container {
    display: none;
    justify-content: space-between; /* To distribute the images evenly */
    left: 36%;
    position: absolute; /* This will allow you to specify exactly where you want this container */
    bottom: 42%; /* Adjust this to position the images closer or further from the bottom */
    width: auto; /* Ensure the container takes the full width of the parent */
  }
  
  .image_idole-container > img {
    /* You can set a width or height to control image size if desired */
    width: auto;
    max-width: 47%;  /* For example */
    height: auto;
  }
}